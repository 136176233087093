<template>
  <div class="contact-us">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="form-outer">
          <div class="main-img">
            <div class="cover-img">
              <img src="../../assets/wedding-form.jpg" class="" alt="" />
            </div>
            <div class="inner">
              <h2>Get on the Wait List!</h2>

              <p class="main-text">
                INCREDEVENT has had an overwhelmingly positive response from our
                first campaign to crowdfund weddings with a party. We have
                closed down new registrations for 2 weeks while we work with our
                first round of customers. However, when we do open back up,
                you'll want to grab this $29 deal immediately so fill out the
                form to get on the wait list!
              </p>
            </div>
          </div>

          <Card>
            <FormulateForm
              class="form"
              name="contact"
              v-model="formValues"
              @submit="handleSubmit"
              #default="{ isLoading }"
              autocomplete="false"
            >
              <div class="row">
                <FormulateInput
                  type="text"
                  name="name"
                  label="Name"
                  placeholder="Name"
                  validation="required"
                  autocomplete="false"
                />
                <FormulateInput
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  validation="email|required"
                  autocomplete="false"
                />

                <FormulateInput
                  type="tel"
                  name="number"
                  label="Phone Number"
                  placeholder="(204) 000 - 0000"
                  validation="required"
                  :validation-messages="{
                    min: 'Invalid phone number provided.'
                  }"
                  autocomplete="false"
                />
              </div>

              <FormulateInput
                type="submit"
                name="Pre-Book"
                :loading="isLoading"
              />
            </FormulateForm>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
a {
  color: white;
  text-decoration: underline;
}
.card {
  border-radius: 10px;
}
#Content .content-inner .row .col {
  margin: 0;
}
#Content .content-inner .row {
  margin: 0;
  width: initial !important;
}

#Content .content-inner {
  padding: 25px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  section {
    margin: 0;
  }
}
.contact-us {
  .form-outer {
    width: 100%;
    // margin-right: 20px;
    // max-width: 1100px;

    div.main-img {
      // background-image: url(../assets/weddings-cover.png);
      position: relative;
      margin-bottom: 24px;
      .cover-img {
        width: 100%;
        img {
          border-radius: 10px;

          width: 100%;
        }
      }
      div.inner {
        position: absolute;
        bottom: 0;
        padding: 10px 20px;

        h2 {
          margin: 0;
          color: white;
          font-size: 40px;
          @media screen and (max-width: 700px) {
            font-size: 20px;
          }
        }
        p {
          font-size: 20px;
        }
      }
    }
    .main-text {
      // font-size: 15px;
      // background: var(--primary-gradient);
      // border-radius: 10px;
      // font-weight: 600;
    }
    .form {
      margin: auto;
      border-radius: 10px;
      .red-box {
        h3 {
          color: white;
          padding: 12px;
          border-radius: 10px;
          background-image: linear-gradient(#e60022, #b40621);
          /* margin-left: 1em; */
          font-size: 1.1em;
          font-weight: 500;
          line-height: 100%;
          word-spacing: 0.025em;
          text-transform: uppercase;
          letter-spacing: 0.05em;
        }
        @media only screen and (max-width: 730px) {
          h3 {
            font-size: 12px;
          }
        }
      }
      // margin-top: 30px;
      // max-width: 602px;

      .button-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .pco {
    width: 34%;

    h3 {
      text-align: center;
      color: white;
      // font-weight: bold;
    }

    img {
      width: 100%;
      max-width: 400px;
    }

    .button-wrapper {
      text-align: center;
      margin-top: 20px;
    }
  }
  .map-cont {
    border-radius: 5px;
    margin-top: 30px;
    width: 100%;
    height: 350px;
    .map {
      border-radius: inherit;
    }
  }
  @media only screen and (max-width: 900px) {
    .form-outer {
      width: 100%;
    }
    .pco {
      width: 100%;
      img {
        // width: initial;
        margin: auto;
        display: block;
      }
    }
    .map-cont {
      height: 300px;

      iframe {
      }
    }
  }
}
</style>

<script>
import ModalContent from "@/helpers/modals";

export default {
  name: "contact-us",
  head() {
    return {
      title: "Contact Us"
    };
  },
  data() {
    return {
      options: [
        { label: "", value: "" }, // empty option indicates no selection by default
        { label: "General", value: "General" },
        { label: "Media/Press", value: "Media/Press" },
        { label: "Partnerships", value: "Partnerships" },
        { label: "Sponsorships", value: "Sponsorships" }
      ],
      formValues: {
        name: "",
        email: "",
        event: "",
        number: "",
        reason: "",
        message: ""
      }
    };
  },
  methods: {
    async handleSubmit() {
      this.$axios
        .post("/emails/wedding-social", {
          name: this.formValues.name,
          email: this.formValues.email,
          number: this.formValues.number
        })
        .then(response => {
          if (response.error) {
            this.$root.alert.open({
              title: "Error",
              msg: response.error
            });
          } else {
            this.$bus.$emit("alert", ModalContent.weddingSuccess);

            this.$formulate.reset("contact");
          }
        });
    }
  }
};
</script>
